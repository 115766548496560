import { ColorPickerComponent } from "@syncfusion/ej2-react-inputs";
import * as R from "ramda";
import React, { PropsWithChildren, useContext } from "react";
import { Field, FormRenderProps } from "react-final-form";
import RAFCheckBox from "../../../../RAFComponents/Inputs/RAFCheckBox";
import RAFChoiceOption from "../../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFDatePickerMUI from "../../../../RAFComponents/Inputs/RAFDatePickerMUI";
import RAFDropdown from "../../../../RAFComponents/Inputs/RAFDropdown";
import RAFDropdownCC from "../../../../RAFComponents/Inputs/RAFDropdownCC";
import RAFDurationPicker from "../../../../RAFComponents/Inputs/RAFDurationPicker";
import {
  Condition,
  ConditionIncludes,
} from "../../../../RAFComponents/Inputs/RAFForm";
import RAFHtmlEditor from "../../../../RAFComponents/Inputs/RAFHtmlEditor";
import RAFTextArea from "../../../../RAFComponents/Inputs/RAFTextArea";
import {
  RAFFormContext,
  getFormValue,
  setFormValue,
} from "../../../../RAFComponents/Inputs/RFFUtils";
import { RAFAttributesContext } from "../../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import {
  isRAFMaxDate,
  isRAFMinDate,
} from "../../../../RAFComponents/helpers/AppHelper";
import {
  IsNullOrWhiteSpace,
  convertUTCDateToLocalTimezone,
  getTimeDifferenceInMinutes,
  isNotEmptyArray,
  isNotNullAndUndefined,
  propertyOf,
} from "../../../../RAFComponents/helpers/utils";
import {
  QueryAttributeJM,
  ValueJson,
} from "../../../../RAFComponents/models/Common/QueryAttributeJM";
import ACAutoCompleteMUI from "../../../../components/shared/ACFormFields/ACAutoCompleteMUI";
import {
  RAFTaskSubType,
  RAFTaskType,
} from "../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import { StandardizedLibraryRow } from "../../../Common/StandardizedLibrary/StandardizedLibraryRow";
import { TaskRow } from "../TaskRow";

interface IProps {
  outerDivId: string;
  defaultTaskTypeValue: string;
  onTaskTypeChange: (value: string) => void;
  showDefaultTaskTypes: boolean;
  isTaskActionCenter: boolean;
}

function ManageTaskActivityCommonInputs({
  showDefaultTaskTypes = true,
  outerDivId,
  ...props
}: PropsWithChildren<IProps>) {
  const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);

  const attributesContext = useContext(RAFAttributesContext);
  const queryAttributes = isNotNullAndUndefined(attributesContext)
    ? attributesContext.queryAttributes
    : null;

  const moduleName: string = RAFEntityName.Task;

  const onChangeTaskType = (value) => {
    setFormValue(rafFormContextValue, propertyOf<TaskRow>("SubType"), null);
    const inputValue = getFormValue(
      rafFormContextValue,
      propertyOf<TaskRow>("TaskDate")
    );

    if (value === RAFTaskType.Appointment || value === RAFTaskType.Call) {
      if (isNotNullAndUndefined(inputValue)) {
        const localTime = convertUTCDateToLocalTimezone(inputValue);
        if (
          isRAFMaxDate(inputValue, localTime) ||
          isRAFMinDate(inputValue, localTime)
        ) {
          const currentTime = new Date(); // roundToNearestInterval(new Date(), 15);
          setFormValue(
            rafFormContextValue,
            propertyOf<TaskRow>("TaskDate"),
            currentTime
          );
          const endDate = new Date(inputValue);
          endDate.setMinutes(endDate.getMinutes() + 30);
          const newEndTime = endDate; // roundToNearestInterval(endDate, 15);
          setFormValue(
            rafFormContextValue,
            propertyOf<TaskRow>("EndDate"),
            newEndTime
          );

          const duration = getTimeDifferenceInMinutes(currentTime, newEndTime);
          setFormValue(
            rafFormContextValue,
            propertyOf<TaskRow>("Duration"),
            duration
          );
        } else {
          const endDate = new Date(inputValue);
          endDate.setMinutes(endDate.getMinutes() + 30);
          const newEndTime = endDate; // roundToNearestInterval(endDate, 15);
          setFormValue(
            rafFormContextValue,
            propertyOf<TaskRow>("EndDate"),
            newEndTime
          );

          const duration = getTimeDifferenceInMinutes(inputValue, newEndTime);
          setFormValue(
            rafFormContextValue,
            propertyOf<TaskRow>("Duration"),
            duration
          );
        }
      } else {
        setFormValue(
          rafFormContextValue,
          propertyOf<TaskRow>("TaskDate"),
          null
        );
        setFormValue(rafFormContextValue, propertyOf<TaskRow>("DueDate"), null);
        setFormValue(rafFormContextValue, propertyOf<TaskRow>("EndDate"), null);
        setFormValue(
          rafFormContextValue,
          propertyOf<TaskRow>("Duration"),
          null
        );
      }
    } else {
      if (value === RAFTaskType.Note) {
        setFormValue(
          rafFormContextValue,
          propertyOf<TaskRow>("TaskDate"),
          new Date()
        );
      } else if (value === RAFTaskType.Action) {
        setFormValue(
          rafFormContextValue,
          propertyOf<TaskRow>("SubType"),
          RAFTaskSubType.Task
        );
      }
      setFormValue(rafFormContextValue, propertyOf<TaskRow>("TaskDate"), null);
      setFormValue(rafFormContextValue, propertyOf<TaskRow>("DueDate"), null);
      setFormValue(rafFormContextValue, propertyOf<TaskRow>("EndDate"), null);
      setFormValue(rafFormContextValue, propertyOf<TaskRow>("Duration"), null);
    }
    if (props.onTaskTypeChange) {
      props.onTaskTypeChange(value);
    }
  };

  const getSupTypeContent = () => {
    return (
      <Field name={propertyOf<TaskRow>("TaskType")}>
        {({ input }) => {
          const taskType = input.value;
          const subTypeProperty = isNotEmptyArray(queryAttributes)
            ? queryAttributes.find((x) => x.AttributeName === "sub_type")
            : null;
          const subTypeValueJson = isNotNullAndUndefined(subTypeProperty)
            ? subTypeProperty.ValueJson
            : null;
          let subTypeOptions: ValueJson[] = [];
          if (isNotEmptyArray(subTypeValueJson)) {
            if (taskType === RAFTaskType.Action) {
              subTypeOptions = subTypeValueJson.filter(
                (x) =>
                  x.DisplayName === RAFTaskSubType.Prevention ||
                  x.DisplayName === RAFTaskSubType.Correction ||
                  x.DisplayName === RAFTaskSubType.Task ||
                  x.DisplayName === RAFTaskSubType.Improvement
              );
            } else if (taskType === RAFTaskType.Call) {
              subTypeOptions = subTypeValueJson.filter(
                (x) =>
                  x.DisplayName === RAFTaskSubType.Inbound ||
                  x.DisplayName === RAFTaskSubType.Outbound
              );
            }
          }

          if (isNotEmptyArray(subTypeOptions)) {
            return (
              <div className="col-md-12">
                <RAFDropdownCC<TaskRow>
                  field={propertyOf<TaskRow>("SubType")}
                  label="Sub Type"
                  placeholder="Select Sub Type"
                  isColorOption
                  uitype="colorpicker"
                  moduleName={moduleName}
                  required
                  displayDropdownType={"outlineDropdownBtn"}
                  allowAdd={false}
                  hasChild
                  showLabel={false}
                  formGroupClassName="mb-0"
                  btnWidth="FullWidth"
                  customDropdownBtnClassname="leftBorder_DropdownBtn_option"
                >
                  {subTypeOptions.map((option, index) => {
                    return (
                      <RAFChoiceOption
                        key={index}
                        label={option.DisplayName}
                        value={option.DisplayName}
                        colorCode={option.ColorCode}
                      />
                    );
                  })}
                </RAFDropdownCC>
              </div>
            );
          } else {
            return null;
          }
        }}
      </Field>
    );
  };

  //Subject Content Start
  const onChangeColorCode = (args) => {
    setFormValue(
      rafFormContextValue,
      propertyOf<TaskRow>("ColourCode"),
      args.currentValue.hex
    );
  };

  const getDisplayOrderSubjectColorPicker = () => {
    const paletteColors = {
      custom: [
        // "#F5F6F8",
        "#FFFBC7",
        "#E6FDC9",
        "#D6F0EF",
        "#D7E8FC",
        "#FDDECD",
        "#DED7FC",
        "#FDF2CE",
        "#E0E3EB",
        "#F5F5F5",
        "#D9F2DE",
        "#FDE2BA",
        "#FEDBDB",
        "#C4E8FE",
        // "#F5D027",
        // "#FF9D4A",
        // "#D6F693",
        // "#C9DF56",
        // "#67C6C2",
        // "#FFCFE1",
        // // "#EB93BB",
        // "#C6A2D2",
        // // "#F0939D",
        // // "#A6CBF5",
        // "#6CD9FA",
        // // "#9FA9FF",
      ],
    };

    const displayOrder = [99, 1, 2, 3, 4, 5];

    return (
      <div className="row gx-2">
        <div className="col-2">
          <RAFDropdown
            field={propertyOf<TaskRow>("SortOrder")}
            label="Order"
            formGroupClassName={"mb-0 raf-caret-hide"}
            showClearButton={false}
          >
            {displayOrder.map((item, index) => {
              return (
                <RAFChoiceOption
                  key={`${propertyOf<TaskRow>("SortOrder")}_${index}`}
                  label={item === 99 ? "Any" : item.toString()}
                  value={item}
                ></RAFChoiceOption>
              );
            })}
          </RAFDropdown>
        </div>
        <div className="col">{getAutoCompleteMUI()}</div>
        <div className="col-auto">
          <div className="form-label-container form-label-container-height mt-1"></div>
          <Field name={propertyOf<TaskRow>("ColourCode")}>
            {({ input }) => {
              return (
                <ColorPickerComponent
                  mode="Palette"
                  value={input.value}
                  showButtons={false}
                  modeSwitcher={false}
                  //columns={5}
                  change={(e) => {
                    onChangeColorCode(e);
                  }}
                  presetColors={paletteColors}
                />
              );
            }}
          </Field>
        </div>
      </div>
    );
  };

  const getAutoCompleteMUI = () => {
    return (
      <Field name={propertyOf<TaskRow>("TaskType")}>
        {({ input }) => {
          const taskType = input.value;

          let customFilter: RAFCustomFilter = {};
          customFilter.Condition = "and";
          customFilter.Rules = [];

          let filter: RAFCustomFilter = {};
          let filterVal: string[] = [];
          filterVal.push("Task");
          filter.Operator = RAFCustomOperator.Equal;
          filter.Value = filterVal;
          filter.Field = propertyOf<StandardizedLibraryRow>("Type");
          customFilter.Rules.push(filter);

          if (isNotNullAndUndefined(taskType)) {
            let filter2: RAFCustomFilter = {};
            let filterVal2: string[] = [];
            filterVal2.push(taskType);
            filter2.Operator = RAFCustomOperator.Equal;
            filter2.Value = filterVal2;
            filter2.Field = propertyOf<StandardizedLibraryRow>("Parent");
            customFilter.Rules.push(filter2);
          }

          return (
            <ACAutoCompleteMUI
              label="Subject"
              field={propertyOf<TaskRow>("Title")}
              placeholder="Select Subject"
              showLabel
              showCreateButton={false}
              url="StandardizedLibrary/LookUp"
              moduleName={RAFEntityName.StandardizedLibrary}
              customFilter={customFilter}
              SearchCreateOptionMode={"Footer"}
              required
              formGroupClassName="mb-0"
            />
          );
        }}
      </Field>
    );
  };

  const getTaskTitleContent = () => {
    return (
      <div className="col-md-12">
        <Field name={propertyOf<TaskRow>("TaskType")}>
          {({ input }) => {
            const taskType = input.value;
            if (taskType === RAFTaskType.Note) {
              return (
                <>
                  <div className="row gx-2">
                    <div className="col-6">
                      <RAFDatePickerMUI
                        field={propertyOf<TaskRow>("TaskDate")}
                        label="Date"
                        placeholder="Select Date"
                      />
                    </div>
                    <div className="col-6">
                      <RAFDurationPicker
                        field={propertyOf<TaskRow>("Duration")}
                        showLabel
                      />
                    </div>
                  </div>
                  {getDisplayOrderSubjectColorPicker()}
                </>
              );
            } else {
              return getAutoCompleteMUI();
            }
          }}
        </Field>
      </div>
    );
  };
  //Subject Content End

  //Description Content Start
  const onChangeHasMentions = (value) => {
    if (value === true) {
      const message = getFormValue(
        rafFormContextValue,
        propertyOf<TaskRow>("Description")
      );
      let selectedUserIdArray = getSelectedUserIdArray(message);
      setFormValue(
        rafFormContextValue,
        propertyOf<TaskRow>("Mentions"),
        selectedUserIdArray ?? []
      );
    } else {
      setFormValue(rafFormContextValue, propertyOf<TaskRow>("Mentions"), []);
    }
  };

  function getSelectedUserIdArray(value) {
    let selectedUserIdArray: string[] = [];
    if (!IsNullOrWhiteSpace(value)) {
      let innerHTML = value;
      const div = document.createElement("div");
      div.innerHTML = innerHTML.trim();
      let tributeItem = div.getElementsByClassName("tribute-mention");
      if (isNotNullAndUndefined(tributeItem) && tributeItem.length > 0) {
        for (var i = 0; i < tributeItem.length; i++) {
          const objFirstItem = tributeItem[i];
          if (
            isNotNullAndUndefined(objFirstItem) &&
            isNotNullAndUndefined(objFirstItem.children) &&
            isNotNullAndUndefined(objFirstItem.children[0]) &&
            isNotNullAndUndefined(objFirstItem.children[0].id)
          ) {
            const objUserId = objFirstItem.children[0].id;
            if (isNotNullAndUndefined(objUserId)) {
              let userExist = false;
              if (isNotNullAndUndefined(selectedUserIdArray)) {
                //userExist = selectedUserIdArray.findIndex(x => x === objUserName) >= 0 ? true : false;
                userExist =
                  selectedUserIdArray.findIndex((x) => x === objUserId) >= 0
                    ? true
                    : false;
              }
              if (!userExist) {
                //selectedUserIdArray.push({ UID: objUserId, Value: objUserName });
                selectedUserIdArray.push(objUserId);
              }
            }
          }
        }
      }
    }
    return selectedUserIdArray;
  }

  const mentionRightContent = () => {
    return (
      <div className="d-flex align-items-center ms-auto">
        <div className="ms-2 me-1">
          <label className={"form-label"}>Mentions</label>
        </div>
        <RAFCheckBox
          field={propertyOf<TaskRow>("HasMentions")}
          label="Has Mentions"
          showLabel={false}
          validate={false}
          labelPosition="right"
          inputFieldClassName="col-auto"
          labelClassName="col"
          formGroupClassName="mb-0"
          rowClassName="row g-2 align-items-center"
          uitype={"switch"}
          hideRequiredMessage
          onChanged={onChangeHasMentions}
        ></RAFCheckBox>
      </div>
    );
  };

  const getMessageTextArea1 = (useMentions: boolean) => {
    return (
      <RAFTextArea
        field={propertyOf<TaskRow>("Description")}
        label="Description"
        showLabel={true}
        //required
        placeholder={
          "Share your thoughts..."
        }
        rows={5}
        htmlEditorHeight="100%"
        rowClassName="row g-0 gy-2"
        useMentions={false}
        formGroupClassName="mb-0"
      />
    );
  };

  const getMessageTextArea = (useMentions: boolean) => {
    return (
      <RAFHtmlEditor
        field={propertyOf<TaskRow>("Description")}
        label="Description"
        showLabel={true}
        //required
        placeholder={
          useMentions
            ? "Share your thoughts... use @ to mention someone"
            : "Share your thoughts..."
        }
        rows={5}
        htmlEditorHeight="100%"
        fullHeight={false}
        labelRightSection={mentionRightContent()}
        rowClassName="row g-0 gy-2"
        mentionsMode="user"
        useMentions={useMentions}
        mentionsField={propertyOf<TaskRow>("Mentions")}
        formGroupClassName="mb-0"
      />
    );
  };

  const getDescriptionContent = () => {
    return (
      <div className="col-md-12">
        <Condition when={"HasMentions"} is={true}>
          {getMessageTextArea(true)}
        </Condition>
        <Condition when={"HasMentions"} is={false}>
          {getMessageTextArea(false)}
        </Condition>
      </div>
    );
  };
  //Description Content End

  const getTaskTypeInput = () => {
    const queryAttributeClone: QueryAttributeJM[] = R.clone(queryAttributes);

    const taskTypeQueryAttribute =
      queryAttributeClone &&
      queryAttributeClone.find((x) => x.AttributeName === "task_type");
    if (
      taskTypeQueryAttribute &&
      isNotEmptyArray(taskTypeQueryAttribute.ValueJson)
    ) {
      if (showDefaultTaskTypes === false) {
        taskTypeQueryAttribute.ValueJson =
          taskTypeQueryAttribute.ValueJson.filter(
            (x) =>
              x.DisplayName === RAFTaskType.Appointment ||
              x.DisplayName === RAFTaskType.Action
          );
      } else if (props.isTaskActionCenter) {
        taskTypeQueryAttribute.ValueJson =
          taskTypeQueryAttribute.ValueJson.filter(
            (x) =>
              x.DisplayName === RAFTaskType.Appointment ||
              x.DisplayName === RAFTaskType.Action ||
              x.DisplayName === RAFTaskType.Call
          );
      } else {
        taskTypeQueryAttribute.ValueJson =
          taskTypeQueryAttribute.ValueJson.filter(
            (x) =>
              x.DisplayName === RAFTaskType.Appointment ||
              x.DisplayName === RAFTaskType.Action ||
              x.DisplayName === RAFTaskType.Call ||
              x.DisplayName === RAFTaskType.Note
          );
      }
    }
    const valueJsonLength =
      isNotNullAndUndefined(taskTypeQueryAttribute) &&
        isNotEmptyArray(taskTypeQueryAttribute.ValueJson)
        ? taskTypeQueryAttribute.ValueJson.length
        : 0;

    return (
      <div className="col-md-12" key={valueJsonLength}>
        <RAFDropdownCC<TaskRow>
          field={propertyOf<TaskRow>("TaskType")}
          label="Type"
          placeholder="Select Type"
          isColorOption
          uitype="colorpicker"
          moduleName={moduleName}
          required
          onChanged={onChangeTaskType}
          allowAdd={false}
          queryAttribute={taskTypeQueryAttribute}
          displayDropdownType={"iconDropdownBtn"}
          formGroupClassName="mb-0"
          showLabel={false}
        ></RAFDropdownCC>
      </div>
    );
  };

  return (
    <>
      {/* <Condition when={propertyOf<TaskRow>("UID")} is={null}>
                {isNullOrUndefined(props.defaultTaskTypeValue) && ( */}
      {getTaskTypeInput()}
      <ConditionIncludes
        when={propertyOf<TaskRow>("TaskType")}
        is={[RAFTaskType.Action, RAFTaskType.Call]}
      >
        {getSupTypeContent()}
      </ConditionIncludes>
      {getTaskTitleContent()}
      {getDescriptionContent()}
    </>
  );
};

export default React.memo(ManageTaskActivityCommonInputs);
